<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">SALARY INCREASE REPORT</h4> </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md3>
          <v-select
            v-model="category_id"
            class="mx-2"
            dense
            outlined
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="month_of"
            class="mx-2"
            dense
            outlined
            label="Month of"
            :items="month_of_items"
            item-value="month_of"
            item-text="month_of"
            @change="selected_category"
          ></v-select>
        </v-flex>
        <v-flex xs12 md3>
          <v-select
            v-model="sorted_by"
            class="mx-2"
            :items="['1-15', '16-30/31']"
            label="Sorted By"
            required
            outlined
            dense
            @change="selected_category"
          ></v-select>
        </v-flex>
      </v-layout>
      <v-simple-table dense class="mt-2">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-uppercase">No.</th>
              <th class="text-uppercase">Name</th>
              <th class="text-uppercase">Position</th>
              <th class="text-uppercase">Branch</th>
              <th class="text-uppercase">Date</th>
              <th class="text-uppercase">From</th>
              <th class="text-uppercase">To</th>
              <th class="text-uppercase">Proposed By</th>
              <th class="text-uppercase">Approved By</th>
              <th class="text-uppercase">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data_items" :key="item.id">
              <td>
                {{
                  data_items
                    .map(function (x) {
                      return x.id
                    })
                    .indexOf(item.id) + 1
                }}
              </td>
              <td>
                {{ item.employee.last_name + ', ' + item.employee.first_name + ' ' + item.employee.middle_name }}
              </td>
              <td>
                {{ item.employee.employee_position.position}}
              </td>
              <td>
                {{ item.employee.branch.branch_code}}
              </td>
              <td>
                {{ date_format(item.date_of_effective).format('MMMM DD, YYYY') }}
              </td>
              <td>
                {{ formatPrice(item.from_rate) }}
              </td>
              <td>
                {{ formatPrice(item.to_rate) }}
              </td>
              <td>
                {{
                  item.employee_creator.last_name +
                  ', ' +
                  item.employee_creator.first_name +
                  ' ' +
                  item.employee_creator.middle_name
                }}
              </td>
              <td v-if="employee_id === 3 || employee_id === 1">
                <v-icon
                  v-if="item.employee_approver === null"
                  color="success"
                  @click="change_stat_compensation(1, item.id)"
                  >{{ icons.mdiThumbUp }}
                </v-icon>
                {{ ' ' }}
                <v-icon
                  v-if="item.employee_approver === null"
                  color="error"
                  @click="change_stat_compensation(0, item.id)"
                  >{{ icons.mdiThumbDown }}
                </v-icon>
                <v-icon v-if="item.employee_approver === null" color="info" @click="edit_compensation(item)"
                  >{{ icons.mdiPencil }}
                </v-icon>
                {{
                  item.employee_approver != null
                    ? item.employee_approver.last_name +
                      ', ' +
                      item.employee_approver.first_name +
                      ' ' +
                      item.employee_creator.middle_name
                    : ''
                }}
              </td>
              <td v-else>
                {{
                  item.employee_approver == null
                    ? ''
                    : item.employee_approver.last_name +
                      ', ' +
                      item.employee_approver.first_name +
                      ' ' +
                      item.employee_approver.middle_name
                }}
              </td>
              <td>
                {{ item.is_approved === 1 ? 'APPROVED' : item.employee_approver == null ?
                (item.employee_id_approver2 == null?'PENDING TO CFO':'PENDING TO PRESIDENT') :
                'DISAPPROVED' }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <v-dialog v-model="is_change_bank_details" persistent max-width="30%">
      <ChangeCompensation
        :key="key"
        :details="details"
        :member_information="{}"
        v-on:response="on_change"
      ></ChangeCompensation>
      <v-btn color="error" @click="is_change_bank_details = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog :snackbar_flag="this.snackbar" :color="this.snackbar_color" :snackbar_text="this.snackbar_text" />
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiAccountCashOutline,
  mdiInformationOutline,
  mdiThumbDown,
  mdiThumbUp,
  mdiPencil,
} from '@mdi/js'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import ChangeCompensation from '@/views/file_interface/search/components_emloyee/components/ChangeCompensation.vue'

const initialState = () => {
  return {
    category_id: '',
    month_of: '',
    sorted_by: '',
    category_items: [],
    month_of_items: [],
    data_items: [],
    is_change_bank_details: false,
    details: {},
    key: 0,
  }
}
export default {
  components: {
    snackBarDialog,
    ChangeCompensation,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiAccountCashOutline,
        mdiInformationOutline,
        mdiThumbDown,
        mdiThumbUp,
        mdiPencil,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    this.initialize_data()
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    ...mapGetters('authentication', ['employee_id', 'position']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('employee_compensation_history', [
      'initialize_salary_increase_report',
      'list_of_salary_increase_report',
      'approve_compensation',
    ]),
    initialize_data() {
      this.initialize_salary_increase_report()
        .then(response => {
          this.category_items = response.data[0].category
          this.month_of_items = response.data[0].month_of
        })
        .catch(error => {
          console.log(error)
        })
    },
    date_format(value) {
      return moment(value)
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selected_category() {
      const data = new FormData()
      data.append('category_id', this.category_id)
      data.append('employee_id', this.employee_id)
      data.append('month_of', this.month_of)
      data.append('sorted_by', this.sorted_by)
      this.list_of_salary_increase_report(data)
        .then(response => {
          this.data_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    change_stat_compensation(value, id) {
      const data = new FormData()
      data.append('id', id)
      data.append('is_approved', value)
      data.append('employee_id_approver', this.employee_id)
      this.approve_compensation(data)
        .then(response => {
          var color = 'error'
          if (response.status === 200) {
            color = 'success'
          }
          this.change_snackbar({
            show: true,
            color: color,
            text: response.data,
          })
          this.saving_data = false
        })
        .catch(error => {
          console.log(error)
        })
    },
    on_change(value) {
      var color = 'error'
      if (value.status === 200) {
        color = 'success'
      }
      this.change_snackbar({
        show: true,
        color: color,
        text: value.data,
      })
      this.selected_category()
      this.is_change_bank_details = false
    },
    edit_compensation(value) {
      this.key++
      this.is_change_bank_details = true
      this.details = value
    },
  },
}
</script>
